import React from "react";
import styled from "styled-components";

const notNone = (prop) => {
  return prop && prop.length > 0;
};

const ProjectCard = ({ projectData }) => {
  const { type, title, created, lastSubmit, desc, lang, tech, githubLink } =
    projectData;

  return (
    <Card>
      <CardTitle>
        <CardLink href={githubLink} target="_blank" rel="noopener noreferrer">
          {title}
        </CardLink>
      </CardTitle>
      <CardSectionLine />
      {notNone(created) && (
        <CardDetails>
          <span>Created</span>: <p className="details">{created}</p>
        </CardDetails>
      )}
      {notNone(desc) && (
        <CardDetails>
          <span>Description</span>: <p className="details">{desc}</p>
        </CardDetails>
      )}
      {Array.isArray(lang) && notNone(lang) && (
        <CardDetails>
          <span>Languages</span>: <p className="details">{lang.join(", ")}</p>
        </CardDetails>
      )}
      {notNone(tech) && (
        <CardDetails>
          <span>Technologies</span>:{" "}
          <p className="details">{tech.join(", ")}</p>
        </CardDetails>
      )}
      {notNone(type) && (
        <CardDetails>
          <span>Type</span>: <p className="details">{type}</p>
        </CardDetails>
      )}
      {notNone(lastSubmit) && (
        <CardDetails>
          <span>Last Submit</span>: <p className="details">{lastSubmit}</p>
        </CardDetails>
      )}
    </Card>
  );
};

export const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  margin: 20px;
  width: 100%;
  max-width: 425px;
  background-color: #e4e1e1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  z-index: 0;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    z-index: 1;
  }
`;

export const CardTitle = styled.h3`
  color: #3d5a36;
  font-weight: bold;
  font-family: "Times New Roman";
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
`;

export const CardDetails = styled.div`
  color: #555;
  margin-bottom: 0.5rem;

  span {
    font-weight: bold;
    color: #333;
  }

  .details {
  }
`;

export const CardLink = styled.a`
  color: #3d5a36;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #659359;
  }
`;

export const CardSectionLine = styled.div`
  background-color: #414040;
  margin: 10px 0;
  height: 2px;
`;

export default ProjectCard;
